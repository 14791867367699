/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy, Check } from 'lucide-react';

// Import mhchem extension for KaTeX
import 'katex/contrib/mhchem/mhchem';

const preprocessChemistry = (content) => {
  // Replace simple \ce{...} with \(\ce{...}\) to ensure it's treated as math
  content = content.replace(/\\ce\{([^}]+)\}/g, '\\(\\ce{$1}\\)');
  
  // Replace complex chemical structures with placeholders
  let complexStructures = [];
  content = content.replace(/\\chemfig(\*\d+)?\{[^}]+\}|\\chemfig\*\d+\([^)]+\)/g, (match) => {
    complexStructures.push(match);
    return `[COMPLEX_CHEM_${complexStructures.length - 1}]`;
  });
  
  return { content, complexStructures };
};

const preprocessLatex = (content) => {
  // Correct common LaTeX typos
  content = content.replace(/\\left\[/g, '\\left[').replace(/\\right\]/g, '\\right]');
  // Ensure proper escaping of parentheses
  content = content.replace(/\\\(/g, '\\(').replace(/\\\)/g, '\\)');
  // Remove incorrect escaping of parentheses
  content = content.replace(/\\left\\\(/g, '\\left(').replace(/\\right\\\)/g, '\\right)');
  // Handle \left( and \right) without escaping
  content = content.replace(/\\left\(/g, '\\left(').replace(/\\right\)/g, '\\right)');

  // Handle standalone parenthesized expressions that look like math
  content = content.replace(/\(([^()]+)\)/g, (match, p1) => {
    if (/[\int\sum\prod\lim\frac]/.test(p1) || /[_^]/.test(p1)) {
      return `\\(${p1}\\)`;
    }
    return match;
  });

  // Handle expressions that look like math but are wrapped in regular parentheses
  content = content.replace(/\(([\w\s=]+(?:\\frac|\\sum|\\prod|\\int)[\w\s\{\}^_]+)\)/g, '\\($1\\)');

  // Handle specific case of function definitions like F(x) = ...
  content = content.replace(/\(([A-Z]\(x\)\s*=\s*[^)]+)\)/g, '\\($1\\)');

  // Handle expressions enclosed in square brackets that look like math
  content = content.replace(/\[(.*?)\]/g, (match, p1) => {
    if (/\\sum|\\frac|\\Delta/.test(p1)) {
      return `\\[${p1}\\]`;
    }
    return match;
  });

  return content;
};

export const MarkdownRenderer = ({ content, onCreateResource, onViewResource }) => {
  const [processedContent, setProcessedContent] = useState(content);

  useEffect(() => {
    let newContent = preprocessLatex(content);
    newContent = preprocessChemistry(newContent).content;
    setProcessedContent(newContent);
  }, [content]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, [rehypeKatex, { strict: false, output: 'html' }]]}
      components={{
        
        
        math: ({value}) => <MathRenderer math={value} />,
        inlineMath: ({value}) => <MathRenderer math={value} inline />,
        p: ({children, ...props}) => {
          // Check if children contain block-level elements
          const hasBlockElements = React.Children.toArray(children).some(
            child => React.isValidElement(child) && /^(ul|ol|table|h[1-6])$/.test(child.type)
          );
          
          if (hasBlockElements) {
            // If there are block elements, render without wrapping p tag
            return <>{children}</>;
          }
          
          // Otherwise, render as normal paragraph
          return <p {...props}>{React.Children.map(children, child => {
            if (typeof child === 'string') {
              return <MathWrapper content={child} />;
            }
            return child;
          })}</p>;
        },
        li: ({children, ...props}) => {
          return <li {...props}>
            {React.Children.map(children, child => {
              if (typeof child === 'string') {
                return <MathWrapper content={child} />;
              }
              return child;
            })}
          </li>;
        },
      }}
    >
      {processedContent}
    </ReactMarkdown>
  );
};

const MathWrapper = ({ content }) => {
  const parts = content.split(/(\\\(.*?\\\)|\([A-Z]\(x\).*?\))/);
  return (
    <>
      {parts.map((part, index) => {
        if (part.startsWith('\\(') && part.endsWith('\\)')) {
          return <MathRenderer key={index} math={part.slice(2, -2)} inline />;
        } else if (part.startsWith('(') && part.endsWith(')') && /[A-Z]\(x\)/.test(part)) {
          return <MathRenderer key={index} math={part.slice(1, -1)} inline />;
        }
        return part;
      })}
    </>
  );
};

const MathRenderer = ({ math, inline = false }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      try {
        const mathString = math ? math.toString() : '';
        katex.render(mathString, ref.current, {
          throwOnError: false,
          errorColor: '#ff0000',
          displayMode: !inline,
          strict: false,
          trust: true,
          macros: {
            "\\cf": "\\textcolor{#1}{#2}", // Define \cf as a color command
          },
        });
      } catch (error) {
        console.error('KaTeX rendering error:', error);
        ref.current.textContent = math ? math.toString() : '';
      }
    }
  }, [math, inline]);

  return <span ref={ref} className={inline ? 'math-inline' : 'math-block'} />;
};


// This function would call your server to render the chemical structure
const fetchChemicalStructureImage = async (chemfigCode) => {
  // Implement the API call to your server here
  // The server should render the chemfig code and return an image URL
  // For now, we'll return a placeholder
  return 'https://placeholder.com/chemical-structure';
};

const PhysicsRenderer = ({ content }) => {
  // Custom rendering logic for physics content
  return <div className="physics-content">{content}</div>;
};

const ChemistryRenderer = ({ content }) => {
  // Custom rendering logic for chemistry content
  return <div className="chemistry-content">{content}</div>;
};

const BiologyRenderer = ({ content }) => {
  // Parse the LaTeX-like content
  const title = content.match(/title=(.*?)\]/)?.[1] || 'Biology Content';
  const bodyContent = content.replace(/\\begin\{tcolorbox\}.*?\n([\s\S]*?)\\end\{tcolorbox\}/s, '$1');

  // Convert LaTeX-like formatting to JSX
  const formattedContent = bodyContent
    .replace(/\\textbf\{(.*?)\}/g, '<strong>$1</strong>')
    .replace(/\\xrightarrow\{(.*?)\}/g, ' → ')
    .replace(/\\\[(.*?)\\\]/g, '<MathRenderer math="$1" />')
    .replace(/\\\((.*?)\\\)/g, '<MathRenderer math="$1" inline />')
    .replace(/\$([^$]+)\$/g, '<MathRenderer math="$1" inline />')
    .replace(/•/g, '•')
    .split('\n\n');

  return (
    <div className="biology-content" style={{ border: '2px solid green', padding: '10px', borderRadius: '5px' }}>
      <h3 style={{ color: 'green' }}>{title}</h3>
      {formattedContent.map((paragraph, index) => (
        <p key={index}>
          <MarkdownRenderer content={paragraph} />
        </p>
      ))}
    </div>
  );
};

const processInlinePhysicsAndChemistry = (line) => {
  // Handle inline physics
  line = line.replace(/\$\$physics\$\$(.*?)\$\$endphysics\$\$/g, '<span class="inline-physics">$1</span>');
  
  // Handle inline chemistry
  line = line.replace(/\\ce\{([^}]+)\}/g, '<span class="inline-chemistry">\\(\\ce{$1}\\)</span>');
  
  return line;
};

export const parseResponse = (text) => {
  const lines = text.split('\n');
  const parsedContent = [];
  let currentList = null;
  let currentListItem = null;
  let inCodeBlock = false;
  let currentCodeBlock = null;
  let inTable = false;
  let tableHeaders = [];
  let tableRows = [];
  let inMathBlock = false;
  let mathBlockContent = '';
  let inPhysicsBlock = false;
  let physicsBlockContent = '';
  let inChemistryBlock = false;
  let chemistryBlockContent = '';
  let inBiologyBlock = false;
  let biologyBlockContent = '';

  const processMathExpression = (line) => {
    // Handle display math
    if (line.trim().startsWith('\\[') && line.trim().endsWith('\\]')) {
      return { type: 'math-block', content: line.trim().slice(2, -2).trim() };
    }
    // Handle inline math
    return line.replace(/\\\((.*?)\\\)/g, (match, p1) => `$${p1.trim()}$`)
               .replace(/\(([\w']+)\)/g, (match, p1) => `(${p1})`);
  };

  const processInlineCode = (line) => {
    const parts = line.split('`');
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return { type: 'inline-code', content: part };
      }
      return { type: 'text', content: part };
    });
  };

  const processListItem = (line) => {
    const trimmedLine = line.trim();
    
    // Check if the line is a horizontal rule
    if (trimmedLine === '---') {
      if (currentList) {
        parsedContent.push(currentList);
        currentList = null;
        currentListItem = null;
      }
      parsedContent.push({ type: 'horizontal-rule' });
      return;
    }

    // Check if the line is a list terminator
    if (trimmedLine === '--') {
      if (currentList) {
        parsedContent.push(currentList);
        currentList = null;
        currentListItem = null;
      }
      return;
    }

    const level = (line.match(/^\s*/)[0].length / 2) + 1;

    // Check if it's a new section header (including the first one without a number)
    if (trimmedLine.match(/^\d+\.\s.*/) || (!currentList && !trimmedLine.startsWith('•') && !trimmedLine.startsWith('-'))) {
      if (currentList) {
        parsedContent.push(currentList);
        currentList = null;
        currentListItem = null;
      }
      const headerContent = trimmedLine.replace(/^\d+\.\s/, '');
      parsedContent.push({ type: 'heading', level: 3, content: headerContent });
      return;
    }

    // If we're starting a new list or the current list is empty
    if (!currentList || currentList.items.length === 0) {
      currentList = { 
        type: 'list', 
        items: [],
        isOrdered: /^\d+\./.test(trimmedLine)
      };
    } else if (level < currentList.items[currentList.items.length - 1].level) {
      // If the indentation level has decreased, finalize the current list and start a new one
      parsedContent.push(currentList);
      currentList = { 
        type: 'list', 
        items: [],
        isOrdered: /^\d+\./.test(trimmedLine)
      };
    }

    const content = trimmedLine.replace(/^(\d+\.|\•|\-)\s*/, '');
    const newItem = { content, subitems: [], level };

    // If this item is at a deeper level than the previous one, make it a subitem
    if (currentList.items.length > 0 && level > currentList.items[currentList.items.length - 1].level) {
      let parent = currentList.items[currentList.items.length - 1];
      while (parent.subitems.length > 0 && parent.subitems[parent.subitems.length - 1].level < level - 1) {
        parent = parent.subitems[parent.subitems.length - 1];
      }
      parent.subitems.push(newItem);
    } else {
      // Otherwise, add it as a new item in the current list
      currentList.items.push(newItem);
    }

    // Update the currentListItem reference
    currentListItem = newItem;
  };

  const cleanupSpaces = (content) => {
    return content.replace(/\s+([.,;:!?])/g, '$1');
  };

  // Add this to handle \ce commands in regular text
  const processChemistryInLine = (line) => {
    return line
      // Handle isotope notation
      .replace(/\\?\(?\^{?(\d+)}?_{?(\d+)}?([A-Z][a-z]?)\\?\)?/g, '\\(^{$1}_{$2}$3\\)')
      // Handle other chemistry notations
      .replace(/\\ce\{([^}]+)\}/g, '\\(\\ce{$1}\\)')
      .replace(/Δ([HSG])/g, '\\(\\Delta $1\\)')
      .replace(/K(_[cp])?(?![\w\d])/g, '\\(K$1\\)')
      // Convert remaining $...$ to \(...\) if they contain specific chemistry notation
      .replace(/\$([^$]+)\$/g, (match, p1) => {
        if (p1.includes('\\ce{') || p1.includes('\\Delta') || p1.includes('_') || p1.includes('^')) {
          return `\\(${p1}\\)`;
        }
        return match;
      })
      // Handle any remaining \(...\) that might not be properly formatted
      .replace(/\\?\(((?:[^()]|\([^()]*\))*)\)\\?/g, (match, p1) => {
        if (p1.includes('\\ce{') || p1.includes('\\Delta') || p1.includes('_') || p1.includes('^')) {
          return `\\(${p1}\\)`;
        }
        return `(${p1})`;
      });
  };

  const finalizePreviousList = () => {
    if (currentList) {
      parsedContent.push(currentList);
      currentList = null;
      currentListItem = null;
    }
  };

  const parseCodeBlockMetadata = (content) => {
    const titleMatch = content.match(/\[CODE_BLOCK_TITLE:\s*(.*?)\]/);
    const versionMatch = content.match(/\[VERSION:\s*(.*?)\]/);
    const previousVersionMatch = content.match(/\[PREVIOUS_VERSION:\s*(.*?)\]/);

    return {
      title: titleMatch ? titleMatch[1] : null,
      version: versionMatch ? versionMatch[1] : null,
      previousVersion: previousVersionMatch ? previousVersionMatch[1] : null,
    };
  };

  const parseEssayMetadata = (content) => {
    const titleMatch = content.match(/\[ESSAY_TITLE:\s*(.*?)\]/);
    const versionMatch = content.match(/\[VERSION:\s*(.*?)\]/);
    const previousVersionMatch = content.match(/\[PREVIOUS_VERSION:\s*(.*?)\]/);

    return {
      title: titleMatch ? titleMatch[1] : null,
      version: versionMatch ? versionMatch[1] : null,
      previousVersion: previousVersionMatch ? previousVersionMatch[1] : null,
    };
  };

  const processLine = (line, lineIndex) => {
    const trimmedLine = line.trim();

    if (inMathBlock) {
      if (trimmedLine === '\\]') {
        parsedContent.push({ type: 'math-block', content: mathBlockContent.trim() });
        inMathBlock = false;
        mathBlockContent = '';
      } else {
        mathBlockContent += line + '\n';
      }
      return;
    }

    if (trimmedLine === '\\[') {
      inMathBlock = true;
      return;
    }

    if (line.startsWith('<code language="')) {
      const languageMatch = line.match(/<code language="(\w+)">/);
      if (languageMatch) {
        inCodeBlock = true;
        currentCodeBlock = { language: languageMatch[1], content: '' };
      }
    } else if (line.startsWith('</code>') && inCodeBlock) {
      parsedContent.push({ type: 'code-block', ...currentCodeBlock });
      inCodeBlock = false;
      currentCodeBlock = null;
    } else if (inCodeBlock) {
      currentCodeBlock.content += line + '\n';
    } else {
      // Handle inline code and other content
      const parts = line.split(/(`[^`]+`)/);
      const processedParts = parts.map(part => {
        if (part.startsWith('`') && part.endsWith('`')) {
          return { type: 'inline-code', content: part.slice(1, -1) };
        }
        return { type: 'text', content: part };
      });
      
      if (processedParts.some(part => part.type === 'inline-code')) {
        parsedContent.push({ type: 'paragraph', content: processedParts });
      } else {
        // Process other types of content...
        // (Keep your existing logic for other content types here)
      }
    }

    if (inCodeBlock) {
      currentCodeBlock.content += line + '\n';
      return;
    }

    if (trimmedLine.startsWith('|') && trimmedLine.endsWith('|')) {
      if (!inTable) {
        inTable = true;
        tableHeaders = trimmedLine.split('|').slice(1, -1).map(header => header.trim());
      } else if (trimmedLine.includes('---')) {
        // This is the separator line, skip it
      } else {
        tableRows.push(trimmedLine.split('|').slice(1, -1).map(cell => cell.trim()));
      }
    } else if (inTable) {
      // End of table
      parsedContent.push({ type: 'table', headers: tableHeaders, rows: tableRows });
      inTable = false;
      tableHeaders = [];
      tableRows = [];
    } else if (trimmedLine.startsWith('$$physics$$')) {
      inPhysicsBlock = true;
      physicsBlockContent = '';
    } else if (inPhysicsBlock && trimmedLine === '$$endphysics$$') {
      parsedContent.push({ type: 'physics-block', content: physicsBlockContent.trim() });
      inPhysicsBlock = false;
      physicsBlockContent = '';
    } else if (inPhysicsBlock) {
      physicsBlockContent += line + '\n';
    } else if (trimmedLine.startsWith('$$chemistry$$')) {
      inChemistryBlock = true;
      chemistryBlockContent = '';
    } else if (inChemistryBlock && trimmedLine === '$$endchemistry$$') {
      parsedContent.push({ type: 'chemistry-block', content: chemistryBlockContent.trim() });
      inChemistryBlock = false;
      chemistryBlockContent = '';
    } else if (inChemistryBlock) {
      chemistryBlockContent += line + '\n';
    } else if (trimmedLine.startsWith('Light-Independent Reactions') || inBiologyBlock) {
      if (!inBiologyBlock) {
        inBiologyBlock = true;
        biologyBlockContent = '\\begin{tcolorbox}[colback=green!5!white,colframe=green!75!black,title=Biology Content]\n';
      }
      biologyBlockContent += line + '\n';
      if (lineIndex === lines.length - 1 || lines[lineIndex + 1].trim() === '') {
        biologyBlockContent += '\\end{tcolorbox}';
        parsedContent.push({ type: 'biology-block', content: biologyBlockContent.trim() });
        inBiologyBlock = false;
        biologyBlockContent = '';
      }
    } else {
      const processedLine = processMathExpression(processChemistryInLine(processInlinePhysicsAndChemistry(trimmedLine)));

      if (typeof processedLine === 'object' && processedLine.type === 'math-block') {
        finalizePreviousList();
        parsedContent.push(processedLine);
      } else {
        if (processedLine.includes('`') || processedLine.includes('<span class="inline-physics">') || processedLine.includes('<span class="inline-chemistry">')) {
          finalizePreviousList();
          parsedContent.push({ type: 'paragraph', content: processInlineCode(processedLine) });
        } else if (processedLine.match(/\*\*.*?\*\*/)) {
          finalizePreviousList();
          const parts = processedLine.split(/(\*\*.*?\*\*)/);
          parsedContent.push({
            type: 'paragraph',
            content: parts.map((part, index) => {
              if (part.startsWith('**') && part.endsWith('**')) {
                return { type: 'strong', content: part.slice(2, -2) };
              }
              return { type: 'text', content: part };
            }),
          });
        } else if (trimmedLine.match(/^#{1,6}\s/)) {
          finalizePreviousList();
          const level = trimmedLine.match(/^#+/)[0].length;
          const content = trimmedLine.replace(/^#+\s/, '');
          parsedContent.push({ type: 'heading', level, content });
        } else if (trimmedLine.startsWith('•') || trimmedLine.startsWith('-') || trimmedLine === '--' || (currentList && trimmedLine)) {
          processListItem(line);
        } else if (trimmedLine.startsWith('> ')) {
          finalizePreviousList();
          parsedContent.push({ type: 'blockquote', content: trimmedLine.slice(2) });
        } else if (trimmedLine.match(/\[.*?\]\(.*?\)/)) {
          finalizePreviousList();
          const linkMatch = trimmedLine.match(/\[(.*?)\]\((.*?)\)/);
          parsedContent.push({ type: 'link', text: linkMatch[1], url: linkMatch[2] });
        } else if (trimmedLine === '---') {
          finalizePreviousList();
          parsedContent.push({ type: 'horizontal-rule' });
        } else if (trimmedLine) {
          finalizePreviousList();
          parsedContent.push({ type: 'paragraph', content: cleanupSpaces(trimmedLine) });
        }
      }
    }
  };

  lines.forEach((line, lineIndex) => processLine(line, lineIndex));

  finalizePreviousList();

  return parsedContent;
};

const renderListItem = (item, index, isOrdered) => (
  <li key={index} className="ai-response-list-item">
    <MarkdownRenderer content={item.content} />
    {item.subitems && item.subitems.length > 0 && (
      isOrdered ? (
        <ol className="ai-response-sublist">
          {item.subitems.map((subitem, subIndex) => renderListItem(subitem, `${index}-${subIndex}`, isOrdered))}
        </ol>
      ) : (
        <ul className="ai-response-sublist">
          {item.subitems.map((subitem, subIndex) => renderListItem(subitem, `${index}-${subIndex}`, isOrdered))}
        </ul>
      )
    )}
  </li>
);

const CodeBlock = ({ language, content }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="modern-code-display">
      <div className="code-header">
        <span className="code-language">{language}</span>
        <button onClick={handleCopy} className="copy-button">
          {copied ? <Check size={16} /> : <Copy size={16} />}
          {copied ? 'Copied!' : 'Copy'}
        </button>
      </div>
      <SyntaxHighlighter
        language={language}
        style={vscDarkPlus}
        wrapLines={true}
        wrapLongLines={true}
        customStyle={{
          margin: 0,
          borderRadius: '0 0 8px 8px',
          padding: '1rem',
        }}
      >
        {content}
      </SyntaxHighlighter>
    </div>
  );
};

export const renderParsedContent = (parsedContent, onCreateResource, onViewResource) => {
  return (
    <div className="ai-response-content">
      {parsedContent.map((item, index) => {
        switch (item.type) {
          case 'heading':
            const HeadingTag = `h${item.level}`;
            return <HeadingTag key={index} className={`ai-response-heading ai-response-heading-${item.level}`}>{item.content}</HeadingTag>;
          case 'paragraph':
            if (Array.isArray(item.content)) {
              return (
                <p key={index} className="ai-response-paragraph">
                  {item.content.map((part, partIndex) => {
                    if (typeof part === 'object') {
                      if (part.type === 'inline-code') {
                        return <code key={partIndex} className="ai-response-inline-code">{part.content}</code>;
                      } else if (part.type === 'strong') {
                        return <strong key={partIndex} className="ai-response-strong">{part.content}</strong>;
                      } else if (part.type === 'text') {
                        return part.content;
                      }
                    } else if (typeof part === 'string') {
                      return <MarkdownRenderer key={partIndex} content={part} />;
                    }
                    return null;
                  })}
                </p>
              );
            }
            return <p key={index} className="ai-response-paragraph"><MarkdownRenderer content={item.content.toString()} /></p>;
          case 'strong':
            return <strong key={index} className="ai-response-strong">{item.content}</strong>;
          case 'list':
            return item.isOrdered ? (
              <ol key={index} className="ai-response-list ai-response-ordered-list">
                {item.items.map((listItem, listIndex) => renderListItem(listItem, listIndex, true))}
              </ol>
            ) : (
              <ul key={index} className="ai-response-list">
                {item.items.map((listItem, listIndex) => renderListItem(listItem, listIndex, false))}
              </ul>
            );
          case 'blockquote':
            return <blockquote key={index} className="ai-response-blockquote">{item.content}</blockquote>;
          case 'link':
            return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer" className="ai-response-link">{item.text}</a>;
          case 'horizontal-rule':
            return <hr key={index} className="ai-response-hr" />;
          case 'table':
            return (
              <div className="table-wrapper" key={index}>
                <table className="ai-response-table">
                  <thead>
                    <tr>
                      {item.headers.map((header, headerIndex) => (
                        <th key={headerIndex}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          case 'math-block':
            return <MathRenderer key={index} math={item.content.toString()} />;
          case 'physics-block':
            return <PhysicsRenderer key={index} content={item.content.toString()} />;
          case 'chemistry-block':
            return <ChemistryRenderer key={index} content={item.content.toString()} />;
          case 'biology-block':
            return <BiologyRenderer key={index} content={item.content.toString()} />;
          case 'code-block':
            return <CodeBlock key={index} language={item.language} content={item.content} />;
          case 'essay':
            // Comment out resource-related code
            /*
            const resourceTitle = item.title || `Essay ${index + 1}`;
            const resource = {
              type: 'essay',
              title: resourceTitle,
              content: item.content,
              version: 1,
              versions: [1]
            };
            
            // Check if a resource with the same title already exists
            const existingResource = onCreateResource(resource);
            
            if (existingResource) {
              // If it exists, update the version
              resource.version = existingResource.version + 1;
              resource.versions = [...existingResource.versions, resource.version];
            }
            
            return (
              <SmartBox
                key={index}
                resource={resource}
                onViewResource={onViewResource}
              />
            );
            */
            // For now, just render the essay content as a paragraph
            return <p key={index} className="ai-response-paragraph">{item.content}</p>;
          default:
            return null;
        }
      })}
    </div>
  );
};

export const AIResponseRenderer = React.memo(({ content, onCreateResource, onViewResource }) => {
  const parsedContent = useMemo(() => parseResponse(content), [content]);
  return renderParsedContent(parsedContent, onCreateResource, onViewResource);
});