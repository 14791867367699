import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Styles/Policy.css';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | StuddyBuddy</title>
        <meta name="description" content="Read StuddyBuddy's privacy policy to understand how we collect, use, and protect your personal information while using our AI-powered study companion." />
        <meta name="keywords" content="StuddyBuddy, privacy policy, data protection, student privacy, AI tutor privacy" />
        <link rel="canonical" href="https://studdybuddy.ca/privacy-policy" />
      </Helmet>
      <div className="policy-container">
        <h1>Privacy Policy</h1>
        <p>Last updated: {new Date().toDateString()}</p>
        
        <section>
          <h2>1. Information We Collect</h2>
          <p>We collect information you provide directly to us, including:</p>
          <ul>
            <li>Account information (name, email, password)</li>
            <li>Profile information</li>
            <li>Payment information (processed securely through Stripe)</li>
            <li>Chat messages and PDF documents you upload</li>
            <li>Usage data and learning history</li>
          </ul>
        </section>
        
        <section>
          <h2>2. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and improve our AI-powered study companion service</li>
            <li>Process payments and manage subscriptions</li>
            <li>Personalize your learning experience</li>
            <li>Communicate with you about our service</li>
            <li>Analyze usage patterns to enhance our offerings</li>
          </ul>
        </section>
        
        <section>
          <h2>3. Data Storage and Security</h2>
          <p>We store your data securely using industry-standard practices. Your chat messages, PDF documents, and other content are stored in our database and R2 storage. We implement appropriate measures to protect your information against unauthorized access or disclosure.</p>
        </section>
        
        <section>
          <h2>4. Third-Party Services</h2>
          <p>We use third-party services to enhance our offerings:</p>
          <ul>
            <li>Stripe for payment processing</li>
            <li>Anthropic's Claude AI for chat interactions</li>
            <li>OpenAI's GPT for chat interactions</li>
            <li>Groq for certain AI processing tasks</li>
            <li>Meta Pixel for analytics and advertising</li>
          </ul>
          <p>These services may collect and process your data according to their own privacy policies.</p>
        </section>
        
        <section>
          <h2>5. User Consent and Data Collection</h2>
          <p>We collect and process certain information about you, you have the right to:</p>
          <ul>
            <li>Access and update your personal information</li>
            <li>Request deletion of your account and associated data</li>
            <li>Opt-out of marketing communications</li>
            <li>Change your subscription or cancel it</li>
          </ul>
          <p>This information is used to improve our services, provide location-based features, and enhance our advertising efforts. We may share this information with third-party services like Meta Pixel for analytics and advertising purposes.</p>
          <p>By using our service, you consent to the collection and processing of this information. You can withdraw your consent at any time by contacting us or adjusting your settings in your account dashboard.</p>
        </section>
        
        <section>
          <h2>6. Data Retention</h2>
          <p>We retain your personal information for as long as necessary to provide our services and comply with legal obligations. If you delete your account, we will remove your personal data from our systems within a reasonable timeframe.</p>
        </section>
        
        <section>
          <h2>7. Children's Privacy</h2>
          <p>StudyBuddy is not intended for children under 13 without adult permission. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13 without proper consent, please contact us immediately.</p>
        </section>

        
        <section>
          <h2>8. Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
        </section>
        
        <section>
          <h2>9. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at legal@studdybuddy.ca.</p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;