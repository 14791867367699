import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { createCheckoutSession } from '../services/api';
import { FaCheck } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import '../Styles/Pricing.css';
import { handleStripePortalRedirect } from '../services/api';

const Pricing = () => {
    useDocumentTitle('StudyBuddy - Pricing');
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();
    const subscription = user?.subscription;

    const [currency, setCurrency] = useState('usd');
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [activeFaqId, setActiveFaqId] = useState(null);

    const pricingTiers = [
        { 
            name: 'Free', 
            price: { usd: 'Free', cad: 'Free' },
            freeTrial: 'No trial needed',
            features: [
                'Access to core AI capabilities',
                'Daily interaction limits of 5 chats',
            ]
        },
        { 
            name: 'Premium', 
            price: { 
                usd: { monthly: '$5.59', annual: '$49.99' },
                cad: { monthly: '$6.99', annual: '$59.99' }
            },
            freeTrial: '7 days',
            features: [
                'Access to core AI capabilities',
                'Increased interaction limits',
                
            ]
        },
        { 
            name: 'Pro', 
            price: { 
                usd: { monthly: '$12.99', annual: '$119.99' },
                cad: { monthly: '$15.99', annual: '$139.99' }
            },
            freeTrial: '7 days',
            features: [
                'Everything in Premium, plus:',
                'Unlimited AI interactions',
                'Priority support'
            ]
        },
        { 
            name: 'Ultimate', 
            price: { 
                usd: { monthly: '$18.99', annual: '$159.99' },
                cad: { monthly: '$21.99', annual: '$189.99' }
            },
            freeTrial: '7 days', 
            features: [
                'Everything in Pro, plus:',
                'Priority response times',
                'Access to beta features',

            ]
        }
    ];

    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;
        if (userLanguage.toLowerCase().includes('ca')) {
            setCurrency('cad');
        } else {
            setCurrency('usd');
        }
    }, []);

    const handleSubscription = async (tier, interval = billingCycle, currencyCode = currency) => {
        if (!isAuthenticated) {
            navigate('/signup', { state: { selectedTier: tier, interval, currency: currencyCode } });
            return;
        }

        try {
            if(subscription && subscription.tier !== "free"){
                handleStripePortalRedirect();
                return;
            }
            const response = await createCheckoutSession(tier, interval, currencyCode);
            if (response.url) {
                window.location.href = response.url;
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const renderButton = (tier) => {
        if (!user) {
            return (
                <button 
                    onClick={() => handleSubscription(tier.name.toLowerCase())} 
                    className="signup-button"
                >
                    {tier.name.toLowerCase() === 'free' ? 'Get Started' : `Choose ${tier.name}`}
                </button>
            );
        }

        if (subscription && subscription.tier !== 'free') {
            if (subscription.tier.toLowerCase() === tier.name.toLowerCase()) {
                return (
                    <button 
                        onClick={handleStripePortalRedirect} 
                        className="signup-button"
                    >
                        Manage Subscription
                    </button>
                );
            } else {
                return (
                    <button 
                        className="signup-button" 
                        style={{visibility: 'hidden'}} 
                        disabled
                    >
                        Invisible
                    </button>
                );
            }
        }

        // For free users
        return (
            <button 
                onClick={() => handleSubscription(tier.name.toLowerCase())} 
                className="signup-button"
            >
                {tier.name.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier.name}`}
            </button>
        );
    };

    useEffect(() => {
        const detectUserLocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                if (data.country_code === 'CA') {
                    setCurrency('cad');
                } else if (data.country_code === 'US') {
                    setCurrency('usd');
                } else {
                    setCurrency('usd'); // Default to USD for other countries
                }
            } catch (error) {
                console.error('Error detecting user location:', error);
                setCurrency('usd'); // Default to USD if there's an error
            }
        };

        detectUserLocation();
    }, []);

    const toggleFaq = (id) => {
        setActiveFaqId(activeFaqId === id ? null : id);
    };

    const CurrencyAndBillingSelector = () => (
        <div className="pricing-controls">
            {/* <div className="toggle-container">
                <span className={currency === 'usd' ? 'active' : ''}>USD</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={currency === 'cad'}
                        onChange={() => setCurrency(currency === 'usd' ? 'cad' : 'usd')}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={currency === 'cad' ? 'active' : ''}>CAD</span>
            </div> */}
            <div className="toggle-container">
                <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={billingCycle === 'annual'}
                        onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={billingCycle === 'annual' ? 'active' : ''}>Annual</span>
            </div>
        </div>
    );

    const faqs = [
        {
            id: 1,
            question: "How does StudyBuddy's AI tutor work?",
            answer: "StudyBuddy is an expertly crafted interface that leverages the power of advanced language models. It analyzes your inputs and learning patterns to provide tailored, interactive educational experiences. Think of it as having a highly intelligent tutor available 24/7, ready to assist you with personalized explanations, targeted practice questions, and customized study materials."
        },
        {
            id: 2,
            question: "Is StudyBuddy suitable for all subjects?",
            answer: "Yes! StudyBuddy covers a wide range of subjects including mathematics, sciences, humanities, and languages. Our AI is constantly learning and expanding its knowledge base."
        },
        {
            id: 3,
            question: "Can I use StudyBuddy on multiple devices?",
            answer: "Absolutely! StudyBuddy is accessible on desktop, tablet, and mobile devices. Your progress syncs across all platforms for seamless learning. Mobile app coming!."
        },
        {
            id: 4,
            question: "What payment methods do you accept?",
            answer: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover."
        },
        {
            id: 5,
            question: "How much does StudyBuddy cost?",
            answer: "We offer flexible pricing plans to suit different needs, ranging from a free tier to premium options. Check our pricing section for current rates. We also offer a free trial for new users on paid plans!"
        },
        {
            id: 6,
            question: "Can I cancel my subscription at any time?",
            answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
        },
        {
            id: 7,
            question: "Is there a refund policy?",
            answer: "We offer a 30-day money-back guarantee for all paid plans. Refunds are dealt with on a case-by-case basis. Please reach out to our support team for assistance."
        },
        {
            id: 8,
            question: "Can I upgrade or downgrade my plan?",
            answer: "Yes, you can change your plan at any time. When upgrading, you'll have immediate access to new features. When downgrading, changes will take effect at the start of your next billing cycle."
        }
    ];

    return (
        <div className="pricing-page">
            <header className="pricing-header">
                <h1>Choose Your Perfect Plan</h1>
                <p>Unlock your full potential with our flexible pricing options</p>
            </header>

            <section className="pricing">
            <CurrencyAndBillingSelector />
                <div className="pricing-grid">
                    {pricingTiers.map((tier) => (
                        <div key={tier.name} className={`pricing-item ${tier.name === 'Pro' ? 'featured' : ''}`}>
                            <h3>{tier.name}</h3>
                            {tier.name !== 'Free' ? (
                                <>
                                    <p className="price">
                                        {tier.price[currency][billingCycle]}
                                        <span className="billing-cycle">
                                            {billingCycle === 'monthly' ? '/month' : '/year'}
                                        </span>
                                    </p>
                                    {billingCycle === 'annual' && (
                                        <p className="annual-savings">
                                            Save {(() => {
                                                const monthlyPrice = parseFloat(tier.price[currency].monthly.replace(/[^0-9.]/g, ''));
                                                const annualPrice = parseFloat(tier.price[currency].annual.replace(/[^0-9.]/g, ''));
                                                return ((monthlyPrice * 12 - annualPrice) / (monthlyPrice * 12) * 100).toFixed(0);
                                            })()}%
                                        </p>
                                    )}
                                </>
                            ) : (
                                <p className="price">{tier.price[currency]}</p>
                            )}
                            <p className="free-trial" style={{ visibility: tier.name === 'Free' ? 'hidden' : 'visible' }}>
                                    {tier.freeTrial} free trial
                            </p>
                            <ul className="feature-list">
                                {tier.features.map((feature, index) => (
                                    <li key={index}><FaCheck className="feature-check" /> {feature}</li>
                                ))}
                            </ul>
                            {renderButton(tier)}
                        </div>
                    ))}
                </div>
                <Link to="/features" className="cta-button secondary">
                        Learn More
                </Link>

            </section>

            <section className="faq">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-grid">
                    {faqs.map((faq) => (
                        <div 
                            key={faq.id} 
                            className={`faq-item ${activeFaqId === faq.id ? 'active' : ''}`}
                            onClick={() => toggleFaq(faq.id)}
                        >
                            <h3 className="faq-question">{faq.question}</h3>
                            <p className="faq-answer">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Pricing;