import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import '../Styles/PaymentConfirmation.css';
import useDocumentTitle from '../UseDocumentTitle';
import { FaCheckCircle, FaRocket } from 'react-icons/fa';

const PaymentConfirmation = () => {
    useDocumentTitle('Payment Confirmed - StudyBuddy');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { checkUser, user, subscription } = useAuth();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        queryClient.clear();
        checkUser();

        const countdownTimer = setInterval(() => {
            setCountdown((prevCount) => prevCount - 1);
        }, 1000);

        const redirectTimer = setTimeout(() => {
            navigate('/chats/new');
        }, 5000);

        return () => {
            clearInterval(countdownTimer);
            clearTimeout(redirectTimer);
        };
    }, [navigate, queryClient, checkUser]);

    const getFeatures = () => {
        switch (subscription?.tier?.toLowerCase()) {
            case 'premium':
                return [
                    'Access to core AI capabilities',
                    'Increased interaction limits',
                    'General study planning tools'
                ];
            case 'pro':
                return [
                    'Everything in Premium',
                    'Unlimited AI interactions',
                    'Priority response time'
                ];
            case 'ultimate':
                return [
                    'Everything in Pro',
                    'Priority response times',
                    'Access to beta features'
                ];
            default:
                return [];
        }
    };

    return (
        <div className="payment-confirmation">
            <div className="confirmation-content">
                <div className="confirmation-header">
                    <FaCheckCircle className="confirmation-icon" />
                    <h1>Welcome Aboard, {user?.name || 'Scholar'}!</h1>
                </div>
                <p className="confirmation-message">Your payment has been confirmed and your account has been upgraded.</p>
                <div className="feature-list">
                    <h2>You now have access to:</h2>
                    <ul>
                        {getFeatures().map((feature, index) => (
                            <li key={index}><FaRocket /> {feature}</li>
                        ))}
                    </ul>
                </div>
                <p className="redirect-message">Your learning journey begins in {countdown} seconds...</p>
                <div className="progress-bar">
                    <div className="progress" style={{width: `${(5 - countdown) * 20}%`}}></div>
                </div>
                <button className="start-now-btn" onClick={() => navigate('/chats/new')}>
                    Start Now
                </button>
            </div>
        </div>
    );
};

export default PaymentConfirmation;