   /* eslint-disable no-loop-func */
   /* eslint-disable react-hooks/exhaustive-deps */
   /* eslint-disable no-unused-vars */
   import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
   import { Helmet } from 'react-helmet';
   import { useParams, useNavigate, Link } from 'react-router-dom';
   import { Send, Menu, Settings, Home, Zap, ChevronDown, Copy, Edit } from 'lucide-react';
   import Sidebar from './Partials/Sidebar';
   import '../Styles/Chat.css';
   import { fetchMessages, sendMessage, createNewChat } from '../services/api';
   import { shuffle } from 'lodash';
   import { allSuggestions } from './Data/AllSuggestions';
   import { MarkdownRenderer, AIResponseRenderer } from './Partials/LLMTextRenderer';
   import { useQuery, useQueryClient } from 'react-query';
   import { toast } from 'react-toastify';
   import useDocumentTitle from '../UseDocumentTitle';
   import { useAuth } from '../hooks/useAuth';
   import studyBuddyLogo from '../assets/StudyBuddy logo white background.png';

   const Chats = ({ subscription, remainingChats: initialRemainingChats }) => {
     const { user } = useAuth();
     useDocumentTitle('Chats - StudyBuddy');
     const [remainingChats, setRemainingChats] = useState(initialRemainingChats);
     const queryClient = useQueryClient();
     const { chatId } = useParams();
     const [messages, setMessages] = useState([]);
     const navigate = useNavigate();
     const [input, setInput] = useState('');
     const inputRef = useRef('');
     const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
     const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
     const messagesEndRef = useRef(null);
     const [suggestions, setSuggestions] = useState([]);
     const [updateSidebarTitle, setUpdateSidebarTitle] = useState(null);
     const [chatIdToUse, setChatIdToUse] = useState(null);
     const [currentChatTitle, setCurrentChatTitle] = useState('');
     const [isLoading, setIsLoading] = useState(false);
     const textareaRef = useRef('');
     const [isInputProcessing, setIsInputProcessing] = useState(false);
     const [isSendingFirstMessage, setIsSendingFirstMessage] = useState(false);

     const [searchQuery, setSearchQuery] = useState('');
     const [showScrollButton, setShowScrollButton] = useState(false);
     const messagesContainerRef = useRef(null);
     const [pendingNavigation, setPendingNavigation] = useState(null);

     useEffect(() => {
       setRemainingChats(initialRemainingChats);
     }, [initialRemainingChats]);

     useEffect(() => {
       setSuggestions(shuffle(allSuggestions).slice(0, 4));
     }, []);

     useEffect(() => {
       console.log('remainingChats:', remainingChats);
     }, [remainingChats]);

     const scrollToBottom = useCallback(() => {
       messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
       setShowScrollButton(false);
     }, []);

     const { data: messagesData, isLoading: isMessagesLoading } = useQuery(
       ['messages', chatId],
       () => fetchMessages(chatId),
       {
         enabled: !!chatId && chatId !== 'new',
         staleTime: 5 * 60 * 1000, // 5 minutes
         cacheTime: 30 * 60 * 1000, // 30 minutes
         onSuccess: (data) => {
           setMessages(data);
           localStorage.setItem(`chat_${chatId}`, JSON.stringify(data));
         },
       }
     );

     const filteredMessages = useMemo(() => {
       if (!searchQuery) return messages;
       return messages.filter(msg => 
         msg.content.toLowerCase().includes(searchQuery.toLowerCase())
       );
     }, [messages, searchQuery]);

     const handleSearch = (e) => {
       setSearchQuery(e.target.value);
     };

     const handleAddChat = async () => {
      try {
        navigate(`/chats/new`);

      } catch (error) {
        console.error('Error creating new chat:', error);
      }
    };

     useEffect(() => {
       if (chatId && chatId !== 'new') {
         const cachedMessages = localStorage.getItem(`chat_${chatId}`);
         if (cachedMessages) {
           setMessages(JSON.parse(cachedMessages));
         } else if (messagesData) {
           setMessages(messagesData);
         }
       } else if (chatId === 'new') {
         setMessages([]);
       }
     }, [chatId, messagesData]);

     useEffect(() => {
       scrollToBottom();
     }, [messages, scrollToBottom]);

     useEffect(() => {
       const handleResize = () => {
         setIsSidebarOpen(window.innerWidth > 768);
         setIsMobile(window.innerWidth <= 768);
       };

       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
     }, []);

     const handleUpdateTitle = useCallback((updateFunction) => {
       console.log('handleUpdateTitle called', { updateFunction });
       setUpdateSidebarTitle(() => updateFunction);
     }, []);

     useEffect(() => {
       console.log('updateSidebarTitle changed', { updateSidebarTitle });
     }, [updateSidebarTitle]);

     useEffect(() => {
       if (chatId === 'new') {
         setChatIdToUse(null);
       } else if (chatId) {
         setChatIdToUse(chatId);
       }
     }, [chatId]);

     const handleSubmit = async (e) => {
       e?.preventDefault?.();
       if (!inputRef.current.trim() || (remainingChats <= 0 && remainingChats != null) || isInputProcessing) {
         console.log('Input is empty, no remaining chats, or input is processing. Returning.');
         return;
       }
       
       const currentInput = inputRef.current;
       const currentTimestamp = new Date().toISOString();
       const userMessage = { id: Date.now(), content: currentInput, is_ai: false, timestamp: currentTimestamp };
       const aiMessage = { id: Date.now() + 1, content: '', is_ai: true, timestamp: currentTimestamp };
       
       try {
         setIsLoading(true);
         setIsInputProcessing(true);
         setIsSendingFirstMessage(true);
         console.log('Proceeding with submission', { chatIdToUse, input: currentInput });
         
         let currentChatId = chatIdToUse;
         let isNewChat = false;
         if (!currentChatId || currentChatId === 'new') {
           const newChat = await createNewChat();
           currentChatId = newChat.id;
           setChatIdToUse(currentChatId);
           isNewChat = true;
         }

         // Add messages to state immediately
         setMessages(prevMessages => [...prevMessages, userMessage, aiMessage]);
         setInput('');
         inputRef.current = '';

         const stream = await sendMessage(currentChatId, currentInput);
         setIsLoading(false);

         const reader = stream.getReader();
         const decoder = new TextDecoder();
         
         let aiResponse = '';
         let newTitle = null;
         let buffer = '';
         let updatedRemainingChats = null;

         while (true) {
           const { done, value } = await reader.read();
           if (done) break;
           
           buffer += decoder.decode(value, { stream: true });
           const lines = buffer.split('\n');
           buffer = lines.pop() || '';

           for (const line of lines) {
             if (line.startsWith('data: ')) {
               try {
                 const data = JSON.parse(line.slice(5));
                 if (data.content) {
                   aiResponse += data.content;
                   setMessages(prevMessages => prevMessages.map(msg => 
                     msg.id === aiMessage.id ? { ...msg, content: aiResponse } : msg
                   ));
                 }
                 if (data.newTitle && isSendingFirstMessage) {
                   newTitle = data.newTitle;
                   setCurrentChatTitle(newTitle);
                 }
                 if (data.remainingChats !== undefined) {
                   updatedRemainingChats = data.remainingChats;
                   setRemainingChats(updatedRemainingChats);
                 }
               } catch (error) {
                 console.error('Error parsing SSE data:', error);
               }
             }
             if (line.startsWith('event: end')) {
               break;
             }
           }
         }

         if (isNewChat) {
           // Update the chats list in the query cache
           queryClient.setQueryData(['chats'], (oldData) => {
             const newChat = { id: currentChatId, title: newTitle || 'New Chat' };
             if (!oldData) return [newChat];
             const updatedChats = Array.isArray(oldData) ? oldData : oldData.chats || [];
             return [newChat, ...updatedChats];
           });

           // Invalidate and refetch in the background
           queryClient.invalidateQueries(['chats'], {
             refetchActive: true,
             refetchInactive: false,
           });

           setPendingNavigation(currentChatId);
         }

         // Update the messages for the current chat
         queryClient.setQueryData(['messages', currentChatId], (oldData) => {
           const newMessages = oldData ? [...oldData, userMessage, { ...aiMessage, content: aiResponse }] : [userMessage, { ...aiMessage, content: aiResponse }];
           return newMessages;
         });

         // Invalidate and refetch messages in the background
         queryClient.invalidateQueries(['messages', currentChatId], {
           refetchActive: true,
           refetchInactive: false,
         });

         if (newTitle) {
           // Update the title in the chats list
           queryClient.setQueryData(['chats'], (oldData) => {
             if (!oldData) return [{ id: currentChatId, title: newTitle }];
             const updatedChats = Array.isArray(oldData) ? oldData : oldData.chats || [];
             return updatedChats.map(chat => 
               chat.id === currentChatId ? { ...chat, title: newTitle } : chat
             );
           });
         }

         // Update remaining chats if received from the server
         if (updatedRemainingChats !== null) {
           setRemainingChats(updatedRemainingChats);
           queryClient.setQueryData(['user'], (oldData) => ({
             ...oldData,
             remainingChats: updatedRemainingChats
           }));
         }

       } catch (error) {
         console.error('Error sending message:', error);
         
         // Remove the temporarily added messages
         setMessages(prevMessages => prevMessages.filter(msg => msg.id !== userMessage.id && msg.id !== aiMessage.id));
         
         // Reset input
         setInput(currentInput);
         inputRef.current = currentInput;

         if (error.message.includes('Daily chat limit reached')) {
           toast.error('You have reached your daily chat limit. Please upgrade your subscription to continue chatting.');
         } else {
           toast.error('An error occurred while sending your message. Please try again.');
         }
       } finally {
         setIsLoading(false);
         setIsInputProcessing(false);
         setIsSendingFirstMessage(false);
       }
     };

     useEffect(() => {
       if (pendingNavigation) {
         const timer = setTimeout(() => {
           navigate(`/chats/${pendingNavigation}`, { replace: true });
           setPendingNavigation(null);
         }, 1000);

         return () => clearTimeout(timer);
       }
     }, [pendingNavigation, navigate]);

     const toggleSidebar = useCallback(() => {
       setIsSidebarOpen(prev => !prev);
     }, []);

     const renderMessage = useCallback((message) => {
       return (
         <div key={message.id} className={`message ${message.is_ai ? 'ai' : 'user'}`}>
           {message.is_ai ? (
             <>
               <AIResponseRenderer
                 content={message.content}
               />
               <div className="message-actions">
                 <button className="action-button" title="Copy message">
                   <Copy size={16} />
                 </button>
               </div>
             </>
           ) : (
             <MarkdownRenderer content={message.content} />
           )}
         </div>
       );
     }, []);

     const renderDefaultDisplay = () => (
       <div className="default-display">
         <img src={studyBuddyLogo} alt="StudyBuddy Logo" className="studybuddy-logo" />
         <ul className="suggestions-list">
           {suggestions.slice(0, isMobile ? 3 : 4).map((suggestion, index) => (
             <li 
               key={index} 
               onClick={() => (remainingChats > 0 || remainingChats == null) ? submitSuggestion(suggestion) : null}
               className={`suggestion-item ${(remainingChats <= 0 && remainingChats != null) ? 'disabled' : ''}`}
              >
               <span>{suggestion}</span>
             </li>
           ))}
         </ul>
         {remainingChats <= 0 && remainingChats != null && (
           <div className="chat-limit-reached">
             <p>Chat limit reached. <Link to="/pricing">Upgrade plan</Link></p>
           </div>
         )}
       </div>
     );

     const submitSuggestion = (suggestion) => {
       console.log('submitSuggestion called', { suggestion });
       if (remainingChats <= 0 && remainingChats != null) {
         console.log('No remaining chats, cannot submit suggestion');
         return;
       }
       setInput(suggestion);
       inputRef.current = suggestion;
       console.log('Input set, calling handleSubmit');
       handleSubmit({ preventDefault: () => {} });
     };

     const renderChatLimitBanner = () => {
       if (remainingChats === null || remainingChats === undefined || remainingChats > 4) return null;
       
       let message = '';
       if (remainingChats <= 0) {
         message = (
           <>
             You have no chats left today. <Link to="/pricing">Consider upgrading your plan for more!</Link>
           </>
         );
       } else {
         message = `You have ${remainingChats} chat${remainingChats === 1 ? '' : 's'} left today.`;
       }

       return (
         <div className="chat-limit-banner">
           {message}
         </div>
       );
     };

     const renderLoadingSymbol = () => (
       <div className="loading-symbol">
         <div className="loading-dot"></div>
         <div className="loading-dot"></div>
         <div className="loading-dot"></div>
       </div>
     );

     useEffect(() => {
       if (textareaRef.current) {
         textareaRef.current.style.height = '23px';
       }
     }, []);

     const adjustTextareaHeight = () => {
       if (textareaRef.current) {
         textareaRef.current.style.height = '23px';
         const newHeight = Math.min(textareaRef.current.scrollHeight, 120);
         textareaRef.current.style.height = `${newHeight}px`;
       }
     };

     const handleScroll = useCallback(() => {
       if (messagesContainerRef.current) {
         const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
         const isAtBottom = scrollHeight - scrollTop <= clientHeight + 100; // 100px threshold
         setShowScrollButton(!isAtBottom);
       }
     }, []);

     useEffect(() => {
       const messagesContainer = messagesContainerRef.current;
       if (messagesContainer) {
         messagesContainer.addEventListener('scroll', handleScroll);
         return () => messagesContainer.removeEventListener('scroll', handleScroll);
       }
     }, [handleScroll]);

     const handleKeyDown = (e) => {
       if (e.key === 'Enter' && !e.shiftKey) {
         e.preventDefault();
         handleSubmit(e);
       }
     };

     return (
       <>
         <Helmet>
           <title>StudyBuddy AI Chat - Personalized Learning Assistant | StuddyBuddy</title>
           <meta name="description" content="Engage with StudyBuddy's AI-powered chat for personalized tutoring, homework help, and academic support across all subjects. Experience adaptive learning with our intelligent study companion." />
           <meta name="keywords" content="StudyBuddy, AI tutor, personalized learning, homework help, academic support, online tutoring, adaptive learning" />
           <link rel="canonical" href="https://studdybuddy.ca/chats/new" />
           <meta property="og:title" content="StudyBuddy AI Chat - Your Personal Learning Assistant" />
           <meta property="og:description" content="Get instant, personalized academic support with StudyBuddy's AI chat. Improve your understanding across all subjects with our intelligent tutoring system." />
           <meta property="og:url" content="https://studdybuddy.ca/chats/new" />
           <meta property="og:type" content="website" />
           <meta property="og:site_name" content="StuddyBuddy" />
           <meta name="twitter:card" content="summary_large_image" />
           <meta name="twitter:title" content="StudyBuddy AI Chat - Personalized Learning Assistant" />
           <meta name="twitter:description" content="Enhance your learning with StudyBuddy's AI chat. Get instant answers, explanations, and academic support tailored to your needs." />
           <script type="application/ld+json">
             {`
               {
                 "@context": "http://schema.org",
                 "@type": "SoftwareApplication",
                 "name": "StudyBuddy AI Chat",
                 "applicationCategory": "EducationalApplication",
                 "offers": {
                   "@type": "Offer",
                   "price": "0",
                   "priceCurrency": "USD"
                 },
                 "description": "AI-powered chat for personalized tutoring and academic support across all subjects.",
                 "operatingSystem": "Web",
                 "url": "https://studdybuddy.ca/chats/new"
               }
             `}
           </script>
         </Helmet>
         <div className={`chat-page ${isSidebarOpen ? 'sidebar-open' : ''}`}>
           <Sidebar 
             isOpen={isSidebarOpen} 
             toggleSidebar={toggleSidebar} 
             updateTitle={handleUpdateTitle}
             currentChatId={chatIdToUse}
             currentChatTitle={currentChatTitle}
           />
          <div className={`chat-header ${isSidebarOpen ? 'sidebar-open' : ''}`}>
             <div className="header-left">
               <button className="menu-button" onClick={toggleSidebar} aria-label="Toggle sidebar">
                 <Menu size={24} />
               </button>
               <button className="edit-new-chat-button" onClick={handleAddChat} aria-label="Edit or start new chat">
                 <Edit size={24} />
               </button>
               <h1 className="logo-title">StudyBuddy</h1>
             </div>
             <div className="header-center">
               <input 
                 type="text" 
                 placeholder="Search conversations..." 
                 className="search-input"
                 value={searchQuery}
                 onChange={handleSearch}
               />
             </div>
             <div className="header-right">
               <Link to="/" className="home-button" aria-label="Go to home">
                 <Home size={24} />
               </Link>
               <Link to="/settings" className="settings-button" aria-label="Open settings">
                 <Settings size={24} />
               </Link>
               <div className="user-avatar">
                 {user && user.name ? user.name.charAt(0).toUpperCase() : ''}
               </div>
             </div>
           </div>
           <div className={`chat-area ${isSidebarOpen ? 'sidebar-open' : ''}`}>
             <div className={`messages-container ${isSidebarOpen ? 'sidebar-open' : ''}`} ref={messagesContainerRef}>
               <div className="messages-content">
                 {filteredMessages.length === 0 && !isSendingFirstMessage ? (
                   searchQuery ? (
                     <div className="no-results">No messages found matching your search.</div>
                   ) : (
                     renderDefaultDisplay()
                   )
                 ) : (
                   <>
                     {filteredMessages.map(renderMessage)}
                     {isLoading && renderLoadingSymbol()}
                   </>
                 )}
                 <div ref={messagesEndRef} />
               </div>
               {showScrollButton && (
                 <button className="scroll-to-bottom" onClick={scrollToBottom} aria-label="Scroll to bottom">
                   <ChevronDown size={20} />
                 </button>
               )}
             </div>
             <div className="input-container">
               <div className="input-area">
                 <form className="input-wrapper" onSubmit={handleSubmit}>
                   <textarea
                     ref={(el) => {
                       textareaRef.current = el;
                       if (el) {
                         el.value = input;
                         inputRef.current = input;
                         adjustTextareaHeight();
                       }
                     }}
                     value={input}
                     onChange={(e) => {
                       setInput(e.target.value);
                       inputRef.current = e.target.value;
                       adjustTextareaHeight();
                     }}
                     onKeyDown={handleKeyDown}
                     onInput={adjustTextareaHeight}
                     placeholder="Type your message here..."
                     aria-label="Chat input"
                     className="chat-input scrollable"
                     disabled={remainingChats <= 0 && remainingChats != null}
                   />
                   <button 
                     type="submit" 
                     disabled={isInputProcessing || !input.trim() || (remainingChats <= 0 && remainingChats != null)} 
                     aria-label="Send message"
                     className="send-button"
                   >
                     <Send size={20} />
                   </button>
                 </form>
               </div>
               {renderChatLimitBanner()}
             </div>
           </div>
         </div>
       </>
     );
   };

   export default React.memo(Chats);