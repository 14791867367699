/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Plus, X, Edit, LogOut, User, BookOpen, MessageCircle, ChevronLeft, ChevronRight, FileText } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import '../../Styles/Sidebar.css'
import { fetchChats, addChat, removeChat, renameChat } from '../../services/api';
import { useQuery, useQueryClient } from 'react-query';
import LoadingOverlay from './LoadingOverlay';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user, logout } = useAuth();
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingChatTitle, setEditingChatTitle] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: chatsData, isLoading: isChatsLoading } = useQuery(
    'chats',
    fetchChats,
    {
      enabled: !!user,
      staleTime: 5 * 60 * 1000, // 5 minutes
      onError: (error) => console.error('Error fetching chats data:', error)
    }
  );

  const chats = chatsData?.chats || [];
  const remainingChats = chatsData?.remainingChats;

  const sortedChats = useMemo(() => {
    return [...chats].sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified));
  }, [chats]);

  const categorizedChats = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);

    return sortedChats.reduce((acc, chat) => {
      const chatDate = new Date(chat.last_modified);
      if (chatDate >= today) {
        acc.today.push(chat);
      } else if (chatDate >= yesterday) {
        acc.yesterday.push(chat);
      } else if (chatDate >= lastWeek) {
        acc.lastWeek.push(chat);
      } else if (chatDate >= lastMonth) {
        acc.lastMonth.push(chat);
      } else {
        acc.older.push(chat);
      }
      return acc;
    }, { today: [], yesterday: [], lastWeek: [], lastMonth: [], older: [] });
  }, [sortedChats]);

  const handleAddChat = async () => {
    try {
      navigate(`/chats/new`);
      if (window.innerWidth <= 768) {
        toggleSidebar();
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const handleRemoveChat = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await removeChat(id);
      queryClient.invalidateQueries('chats');
      queryClient.removeQueries(['messages', id]);
      localStorage.removeItem(`chat_${id}`);

      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();

      if (id === currentChatId) {
        const mostRecentChat = sortedChats[0];
        navigate(`/chats/${mostRecentChat.id}`);
      } else {
        navigate('/chats/new');
      }
    } catch (error) {
      console.error('Error removing chat:', error);
    }
  };

  const handleRenameChat = async (id) => {
    try {
      await renameChat(id, editingChatTitle);
      queryClient.invalidateQueries('chats');
      setEditingChatId(null);
      setEditingChatTitle('');
    } catch (error) {
      console.error('Error renaming chat:', error);
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const renderChatList = (chats, title) => {
    if (chats.length === 0) return null;
    return (
      <>
        <h4>{title}</h4>
        <ul>
          {chats.map((chat) => (
            <li key={chat.id}>
              {editingChatId === chat.id ? (
                <form onSubmit={(e) => { e.preventDefault(); handleRenameChat(chat.id); }}>
                  <input
                    value={editingChatTitle}
                    onChange={(e) => setEditingChatTitle(e.target.value)}
                    onBlur={() => handleRenameChat(chat.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        setEditingChatId(null);
                        setEditingChatTitle('');
                      }
                    }}
                    autoFocus
                  />
                </form>
              ) : (
                <NavLink to={`/chats/${chat.id}`} onClick={() => {
                  if (window.innerWidth <= 768) {
                    toggleSidebar();
                  }
                }}>
                  <span className="chat-title">{chat.title || 'Untitled Chat'}</span>
                  <div className="button-group">
                    <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditingChatId(chat.id); setEditingChatTitle(chat.title); }} className="edit-btn">
                      <Edit size={14} />
                    </button>
                    <button onClick={(e) => handleRemoveChat(chat.id, e)} className="remove-btn">
                      <X size={14} />
                    </button>
                  </div>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <aside className={`sidebar ${isOpen ? 'open' : 'collapsed'}`}>
      <div className="sidebar-header">
        <User size={20} />
        <span className="user-email">{user?.email}</span>
        <button className="collapse-btn" onClick={toggleSidebar}>
          <ChevronLeft size={24} />
        </button>
      </div>
      {isOpen && (
        <>
          <nav className="sidebar-nav">
            <section className="scrollable-section">
              {isChatsLoading ? (
                <LoadingOverlay />
              ) : (
                <>
                  {chats.length > 0 ? (
                    <>
                      {renderChatList(categorizedChats.today, "Today")}
                      {renderChatList(categorizedChats.yesterday, "Yesterday")}
                      {renderChatList(categorizedChats.lastWeek, "Last 7 Days")}
                      {renderChatList(categorizedChats.lastMonth, "Last 30 Days")}
                      {renderChatList(categorizedChats.older, "Older")}
                    </>
                  ) : (
                    <p>No Chats Available</p>
                  )}
                </>
              )}
            </section>
            <button onClick={handleAddChat} className="add-btn"><Plus size={14} /> New Chat</button>
          </nav>
          <button onClick={handleLogout} className="logout-btn">
            <LogOut size={16} /> Logout
          </button>
        </>
      )}
    </aside>
  );
};

export default React.memo(Sidebar);