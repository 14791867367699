import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Features.css';
import useDocumentTitle from '../UseDocumentTitle';
import { useAuth } from '../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';
import LoadingOverlay from './Partials/LoadingOverlay';
import { Helmet } from 'react-helmet';
import { FaRobot, FaUserGraduate, FaChartLine, FaLock } from 'react-icons/fa';

const StudyBuddyFeatures = () => {
  useDocumentTitle('StudyBuddy Features - AI-Powered Learning Tools for Students');
  const { user, isAuthenticated } = useAuth();
  const subscription = user?.subscription;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeFaqId, setActiveFaqId] = useState(null);

  const handleSubscription = async (tier, interval = 'monthly', currency = 'usd') => {
    if (!isAuthenticated) {
      navigate('/signup', { state: { selectedTier: tier, interval, currency } });
      return;
    }

    try {
      setIsLoading(true);
      if(subscription && subscription.tier !== "free"){
        await handleStripePortalRedirect();
        return;
      }
      const response = await createCheckoutSession(tier, interval, currency);
      if (response.url) {
        window.location.href = response.url;
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderButton = (tier) => {
    if (!user) {
      return (
        <button 
          onClick={() => handleSubscription(tier.toLowerCase(), 'monthly', 'usd')} 
          className="cta-button"
        >
          {tier.toLowerCase() === 'free' ? 'Get Started' : `Choose ${tier}`}
        </button>
      );
    }

    if (subscription && subscription.tier !== 'free') {
      if (subscription.tier.toLowerCase() === tier.toLowerCase()) {
        return (
          <button onClick={handleStripePortalRedirect} className="cta-button">
            Manage Subscription
          </button>
        );
      } else {
        return (
          <button 
            className="cta-button" 
            style={{visibility: 'hidden'}} 
            disabled
          >
            Invisible
          </button>
        );
      }
    }

    // For free users
    return (
      <button 
        onClick={() => handleSubscription(tier.toLowerCase(), 'monthly', 'usd')} 
        className="cta-button"
      >
        {tier.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier}`}
      </button>
    );
  };

  const features = [
    { icon: <FaRobot />, title: "Advanced AI Tutor", description: "Powered by cutting-edge language models for personalized learning" },
    { icon: <FaUserGraduate />, title: "Adaptive Learning", description: "Tailors content to your unique learning style and pace" },
    { icon: <FaChartLine />, title: "Progress Tracking", description: "Monitor your academic growth with detailed analytics" },
    { icon: <FaLock />, title: "Secure Platform", description: "Your data is protected with state-of-the-art encryption" }
  ];

  const faqs = [
    { 
      id: 1, 
      question: "How does StudyBuddy's AI compare to human tutors?", 
      answer: "While StudyBuddy's AI offers 24/7 availability and vast knowledge across subjects, it complements rather than replaces human tutors. It excels in providing instant explanations, personalized practice, and adaptive learning experiences. Human tutors offer irreplaceable emotional support and complex problem-solving guidance, making StudyBuddy an ideal supplement to traditional tutoring."
    },
    { 
      id: 2, 
      question: "Is StudyBuddy suitable for all academic levels?", 
      answer: "Yes, StudyBuddy adapts its content and explanations to suit various academic levels, from middle school to postgraduate studies. Our AI tailors its responses based on the user's indicated educational level, demonstrated knowledge, and learning pace, ensuring a personalized experience for every student."
    },
    { 
      id: 3, 
      question: "What subjects does StudyBuddy cover?", 
      answer: "StudyBuddy covers all academic subjects across various disciplines. This includes mathematics, natural sciences (physics, chemistry, biology, etc.), social sciences (psychology, sociology, economics, etc.), humanities (history, literature, philosophy, etc.), languages, computer science, engineering, business studies, and more. Our AI's comprehensive knowledge base spans the entire spectrum of academic fields, ensuring support for students in virtually any area of study."
    },
    { 
      id: 4, 
      question: "How does StudyBuddy's adaptive learning work?", 
      answer: "StudyBuddy uses advanced algorithms to analyze your interactions, learning patterns, and performance. It then adjusts the difficulty, pace, and style of content delivery to match your unique needs. This ensures that you're always challenged but not overwhelmed, optimizing your learning experience and retention."
    },
    { 
      id: 5, 
      question: "Can StudyBuddy help with test preparation?", 
      answer: "Absolutely! StudyBuddy is an excellent tool for test prep. It can generate practice questions, provide explanations for complex topics, offer study strategies, and even simulate test-like conditions. Whether you're preparing for standardized tests or subject-specific exams, StudyBuddy can tailor its assistance to your specific needs."
    },
    { 
      id: 6, 
      question: "Is StudyBuddy available on mobile devices?", 
      answer: "Yes, StudyBuddy is accessible on desktop, tablet, and mobile devices. Your progress syncs across all platforms for seamless learning. We're also developing a dedicated mobile app to enhance the on-the-go learning experience, coming soon!"
    },
    { 
      id: 7, 
      question: "How does StudyBuddy ensure the accuracy of its information?", 
      answer: "StudyBuddy's AI is trained on vast amounts of verified educational content and is regularly updated with the latest information. However, we always encourage users to cross-reference important information with their textbooks or other authoritative sources. Our team of educators also reviews and refines the AI's knowledge base to ensure high standards of accuracy."
    },
    { 
      id: 8, 
      question: "Can StudyBuddy help with homework assignments?", 
      answer: "StudyBuddy is designed to assist with homework by explaining concepts, providing step-by-step guidance, and offering practice problems. However, it's important to note that StudyBuddy is an educational tool, not a homework completion service. It aims to help you understand and learn, rather than simply provide answers."
    },
    { 
      id: 9, 
      question: "Is StudyBuddy compatible with both US and Canadian education systems?", 
      answer: "Yes, StudyBuddy is designed to support both US and Canadian curricula at all levels: high school, college, and university. Our adaptive learning algorithms ensure personalized education for students in both countries, taking into account the specific requirements and standards of each education system."
    }
  ];

  const toggleFaq = (id) => {
    setActiveFaqId(activeFaqId === id ? null : id);
  };

  return (
    <div className="features-container">
      <Helmet>
        <title>StudyBuddy Features - AI-Powered Learning Tools for Students | StuddyBuddy</title>
        <meta name="description" content="Discover StudyBuddy's AI-powered features: personalized tutoring, adaptive learning, and progress tracking. Elevate your academic performance with our cutting-edge study tools." />
        <meta name="keywords" content="StudyBuddy, AI tutor, adaptive learning, personalized education, student tools, online learning, academic improvement" />
        <link rel="canonical" href="https://studdybuddy.ca/features" />
        <meta property="og:title" content="StudyBuddy Features - AI-Powered Learning Tools | StuddyBuddy" />
        <meta property="og:description" content="Explore StudyBuddy's innovative AI features designed to enhance your learning experience and boost academic performance. Try our personalized tutoring today!" />
        <meta property="og:url" content="https://studdybuddy.ca/features" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="StuddyBuddy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="StudyBuddy Features - AI-Powered Learning Tools | StuddyBuddy" />
        <meta name="twitter:description" content="Discover how StudyBuddy's AI-powered features can revolutionize your learning experience. Personalized tutoring, adaptive learning, and more!" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "StudyBuddy",
              "description": "AI-powered learning platform for students",
              "brand": {
                "@type": "Brand",
                "name": "StuddyBuddy"
              },
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "USD",
                "lowPrice": "0",
                "highPrice": "18.99",
                "offerCount": "4"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "1024"
              },
              "url": "https://studdybuddy.ca/features"
            }
          `}
        </script>
      </Helmet>
      <LoadingOverlay isLoading={isLoading} />
      
      <header className="features-header">
        <h1>Unlock Your Learning Potential with StudyBuddy</h1>
        <p>Harness the power of AI for personalized, efficient studying</p>
      </header>

      <section className="feature-highlights">
        <h2>Key Features</h2>
        <div className="feature-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              {feature.icon}
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="ai-core">
        <h2>State-of-the-Art AI Technology</h2>
        <p>
          StudyBuddy leverages advanced language models to provide intelligent, context-aware assistance across all academic subjects.
          Our AI understands complex queries and delivers clear, concise explanations tailored to your educational level.
        </p>
        <div className="ai-showcase">
          <div className="ai-example">
            <h3>Deep Conceptual Understanding</h3>
            <p>"Explain quantum entanglement for a physics undergraduate."</p>
          </div>
          <div className="ai-example">
            <h3>Interdisciplinary Connections</h3>
            <p>"How does economic scarcity relate to ecological biodiversity?"</p>
          </div>
        </div>
      </section>

      <section className="personalization">
        <h2>Personalized Learning Experience</h2>
        <p>
          StudyBuddy adapts to your unique learning style, remembering your progress and tailoring its approach to optimize your understanding and retention.
        </p>
        <ul className="personalization-features">
          <li>Dynamic difficulty adjustment</li>
          <li>Cross-session context retention</li>
          <li>Interest-based example generation</li>
          <li>Proactive learning suggestions</li>
        </ul>
      </section>

      <section className="tier-benefits">
        <h2>Choose Your Ideal Study Plan</h2>
        <p>
          Unlock advanced features and enhanced learning capabilities with our premium tiers.
        </p>
        <div className="tier-comparison">
          <div className="tier">
            <h3>Free</h3>
            <ul>
              <li>Access to core AI capabilities</li>
              <li>Daily interaction limits of 5 chats</li>
            </ul>
            {renderButton('free')}
          </div>
          <div className="tier">
            <h3>Premium</h3>
            <ul>
              <li>Access to core AI capabilities</li>
              <li>Increased interaction limits</li>
              <li>General study planning tools</li>
            </ul>
            {renderButton('Premium')}
          </div>
          <div className="tier">
            <h3>Pro</h3>
            <ul>
              <li>Everything in Premium, plus:</li>
              <li>Unlimited AI interactions</li>
              <li>Priority response time</li>
            </ul>
            {renderButton('Pro')}
          </div>
          <div className="tier">
            <h3>Ultimate</h3>
            <ul>
              <li>Everything in Pro, plus:</li>
              <li>Priority response times</li>
              <li>Access to beta features</li>
            </ul>
            {renderButton('Ultimate')}
          </div>
        </div>
      </section>

      <section className="comparison">
        <h2>StudyBuddy vs. Leading AI Models</h2>
        <p>See how our AI-powered learning assistant compares to top language models across key educational benchmarks.</p>
        <table>
            <thead>
                <tr>
                    <th>Benchmark</th>
                    <th>StudyBuddy Dynamic API</th>
                    <th>Grok-2</th>
                    <th>Claude 3.5 Sonnet</th>
                    <th>Gemini Pro 1.5</th>
                    <th>Llama 3 405B</th>
                    <th>GPT-4o</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>GPQA</td>
                    <td>59.6%</td>
                    <td>56.0%</td>
                    <td>59.6%</td>
                    <td>46.2%</td>
                    <td>51.1%</td>
                    <td>53.6%</td>
                </tr>
                <tr>
                    <td>MMLU</td>
                    <td>88.7%</td>
                    <td>87.5%</td>
                    <td>88.3%</td>
                    <td>85.9%</td>
                    <td>88.6%</td>
                    <td>88.7%</td>
                </tr>
                <tr>
                    <td>MMLU-Pro</td>
                    <td>76.1%</td>
                    <td>75.5%</td>
                    <td>76.1%</td>
                    <td>69.0%</td>
                    <td>73.3%</td>
                    <td>72.6%</td>
                </tr>
                <tr>
                    <td>MATH§</td>
                    <td>76.6%</td>
                    <td>76.1%</td>
                    <td>71.1%</td>
                    <td>67.7%</td>
                    <td>73.8%</td>
                    <td>76.6%</td>
                </tr>
                <tr>
                    <td>HumanEval</td>
                    <td>92.0%</td>
                    <td>88.4%</td>
                    <td>92.0%</td>
                    <td>71.9%</td>
                    <td>89.0%</td>
                    <td>90.2%</td>
                </tr>
                <tr>
                    <td>MMMU</td>
                    <td>69.1%</td>
                    <td>66.1%</td>
                    <td>68.3%</td>
                    <td>62.2%</td>
                    <td>64.5%</td>
                    <td>69.1%</td>
                </tr>
                <tr>
                    <td>MathVista</td>
                    <td>67.7%</td>
                    <td>69.0%</td>
                    <td>67.7%</td>
                    <td>63.9%</td>
                    <td>—</td>
                    <td>63.8%</td>
                </tr>
                <tr>
                    <td>DocVQA</td>
                    <td>95.2%</td>
                    <td>93.6%</td>
                    <td>95.2%</td>
                    <td>93.1%</td>
                    <td>92.2%</td>
                    <td>92.8%</td>
                </tr>
            </tbody>
        </table>
      </section>

      <section className="real-world-impact">
        <h2>Proven Academic Improvement</h2>
        <p>
          StudyBuddy users report significant enhancements in comprehension, information retention, and academic confidence across various subjects and educational levels.
        </p>
        <div className="impact-stats">
          <div className="stat">
            <strong>94%</strong>
            <span>of beta users improved their grades</span>
          </div>
          <div className="stat">
            <strong>87%</strong>
            <span>reported better subject understanding</span>
          </div>
          <div className="stat">
            <strong>3.2x</strong>
            <span>faster problem-solving on average</span>
          </div>
        </div>
      </section>

      <section className="future-learning">
        <h2>Embrace the Future of Education</h2>
        <p>
          Stay at the forefront of AI-assisted learning with StudyBuddy. We continuously enhance our platform to provide you with the most advanced and effective study tools available.
        </p>
        <Link to="/signup" className="cta-button">Start Your Free Trial Today</Link>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-grid">
          {faqs.map((faq) => (
            <div 
              key={faq.id} 
              className={`faq-item ${activeFaqId === faq.id ? 'active' : ''}`}
              onClick={() => toggleFaq(faq.id)}
            >
              <h3 className="faq-question">{faq.question}</h3>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default StudyBuddyFeatures;