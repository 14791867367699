import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Styles/DevUpdates.css';

const DevUpdates = () => {
  const updates = [
    {
      date: '2024-09-01',
      title: 'StudyBuddy v1.0 Released',
      description: `
        We are thrilled to announce the release of StudyBuddy v1.0! This version includes:
      `,
      features: [
        '24/7 AI-powered tutor for instant academic assistance',
        'Personalized learning experiences tailored to individual needs',
        'Comprehensive subject coverage across various disciplines',
        'Adaptive difficulty scaling to match user proficiency',
        'Contextual memory across sessions for seamless learning',
        'Customized examples and proactive suggestions for better understanding',
        'Multiple subscription tiers (Free, Premium, Pro, Ultimate) to suit different needs',
        'Real-time chat interface with markdown and AI response rendering for enhanced communication',
        'Sidebar for easy navigation and efficient chat management',
        'Performance optimizations and lazy loading for a smoother user experience',
      ]
    }
  ];

  return (
    <>
      <Helmet>
        <title>Developer Updates | StuddyBuddy</title>
        <meta name="description" content="Stay informed about the latest features and improvements to StuddyBuddy, your AI-powered study companion. Check our developer updates for the newest enhancements." />
        <meta name="keywords" content="StuddyBuddy updates, AI tutor improvements, educational software updates, study app features" />
        <link rel="canonical" href="https://studdybuddy.ca/dev-updates" />
      </Helmet>
      <div className="dev-updates">
        <div className='dev-header'>
          <h1>Developer Updates</h1>
        </div> 
        {updates.map((update, index) => (
          <div key={index} className="update-item">
            <h2>{update.title}</h2>
            <p className="update-date">{update.date}</p>
            <p>{update.description}</p>
            <ul className="feature-list">
              {update.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default DevUpdates;