/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import { login as apiLogin, getProfile, logout as apiLogout } from '../services/api';
import { useQueryClient } from 'react-query';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const queryClient = useQueryClient();

  const checkUser = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const profileData = await getProfile();
        setUser(profileData);
        setSubscription(profileData.subscription);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error checking user:', error);
        logout();
      }
    } else {
      logout();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  const login = async (email, password) => {
    try {
      const userData = await apiLogin(email, password);
      const profileData = await getProfile();
      setUser(profileData);
      setSubscription(profileData.subscription);
      setIsAuthenticated(true);
      return userData.data;
    } catch (error) {
      console.error('Login error in useAuth:', error);
      if (error.response) {
        throw new Error(error.response.data.message || 'Login failed. Please try again.');
      } else if (error.request) {
        throw new Error('No response from server. Please check your internet connection.');
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const logout = useCallback(() => {
    apiLogout();
    setUser(null);
    setIsAuthenticated(false);
    setSubscription(null);

    queryClient.clear();

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('chat_')) {
        localStorage.removeItem(key);
      }
    });
    
    localStorage.removeItem('token');
  }, [queryClient]);

  return (
    <AuthContext.Provider value={{ 
      user, 
      isLoading, 
      isAuthenticated, 
      login, 
      logout, 
      checkUser, 
      globalLoading, 
      setGlobalLoading,
      subscription
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);