/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signUp, createCheckoutSession, login, sendVerificationEmail } from '../../services/api';
import '../../Styles/AuthForms.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = () => {
  const [userData, setUserData] = useState({name: '', email: '', password: '', phone_number: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTier = location.state?.selectedTier || 'free';
  const selectedInterval = location.state?.interval || 'monthly';
  const selectedCurrency = location.state?.currency || 'usd';

  useEffect(() => {
    console.log('Selected tier:', selectedTier, 'Interval:', selectedInterval, 'Currency:', selectedCurrency);
  }, [selectedTier, selectedInterval, selectedCurrency]);

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!agreeTerms) {
      setError('You must agree to the Terms and Conditions to sign up.');
      setIsLoading(false);
      return;
    }

    // Password validation
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d|.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(userData.password)) {
      setError('Password must be at least 8 characters long and contain a number or symbol.');
      setIsLoading(false);
      return;
    }

    console.log('Submitting signup form for tier:', selectedTier);
    try {
      // Send verification email
      await signUp({ ...userData, selectedTier, selectedInterval, selectedCurrency });
      setSuccess('Verification email sent! Please check your email to complete the registration.');
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-container">
        <div className="branding">
        <Link to="/" className="logo">StudyBuddy</Link>
        <p className="tagline">Your AI-Powered Learning Companion</p>
      </div>
      <div className="auth-form">
      
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={userData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={userData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                required
                value={userData.password}
                onChange={handleChange}
              />
              <button 
                type="button" 
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
            />
            <label htmlFor="agreeTerms">
              I agree to the <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </label>
          </div>
          <button type="submit" className="submit-button" disabled={isLoading || !agreeTerms}>
            {isLoading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </form>
        <p className="auth-link">
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;