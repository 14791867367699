/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { verifyEmailToken, createVerifiedUser, createCheckoutSession } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import '../../Styles/AuthForms.css';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [oneTimeToken, setOneTimeToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const { login } = useAuth();

  useEffect(() => {
    const verifyToken = async () => {
      if (isVerifying) return;
      setIsVerifying(true);

      try {
        const response = await verifyEmailToken(token);
        if (response.oneTimeToken) {
          setOneTimeToken(response.oneTimeToken);
          setUserData(response.data);
          setMessage('Email verified. Creating your account...');
          await createUser(response.oneTimeToken, response.data);
        } else {
          setMessage('Invalid or expired token.');
        }
      } catch (error) {
        console.error('Email verification error:', error);
        if (error.response?.data?.error === 'User already exists') {
          setMessage('This verification link has already been used.');
        } else {
          setMessage('Verification failed. Please try again.');
        }
      } finally {
        setIsVerifying(false);
      }
    };

    verifyToken();
  }, [token]);

  const createUser = async (oneTimeToken, userData) => {
    try {
      const response = await createVerifiedUser(oneTimeToken);
      if (response.token) {
        setMessage('Account created successfully! Logging you in...');
        
        try {
          // Use the login function from useAuth
          await login(response.data.email, response.data.password);
          
          // Check if the user selected a paid tier
          if (userData.selectedTier && userData.selectedTier !== 'free') {
            const checkoutSession = await createCheckoutSession(userData.selectedTier, userData.selectedInterval, userData.selectedCurrency);
            window.location.href = checkoutSession.url;
          } else {
            navigate('/chats/new');
          }
        } catch (loginError) {
          console.error('Login error:', loginError);
          setMessage('Account created, but login failed. Please try logging in manually.');
        }
      } else {
        setMessage('Failed to create account. Please try again.');
      }
    } catch (error) {
      console.error('User creation error:', error);
      setMessage('Failed to create account. Please try again.');
    }
  };

  return (
    <div className="auth-container">
        
      <div className="auth-form">
      <div className="branding">
            <Link to="/" className="logo">StudyBuddy</Link>
        <p className="tagline">Your AI-Powered Learning Companion</p>
      </div>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default VerifyEmail;